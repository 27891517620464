import axios from 'axios'
import router from "@/router";
import store from "@/store";

const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API ?  process.env.VUE_APP_BASE_API :'http://112.124.71.128:9090/backend-server'  ,
    timeout: 5000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    config.headers['authorization'] = sessionStorage.getItem("token")||'';
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        console.log("response:",response)
        const authorization =  response.headers.authorization
        if(authorization){
            sessionStorage.setItem("token",authorization)
        }
        let res = response.data
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        if(error.response && error.response.status == 401){
            store.commit("logout")
            return
        }
        return Promise.reject(error)
    }
)


export default request

