import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  { path: '/', 
    name: 'Manage', 
    component: () => import('../views/Manage.vue'), 
    redirect: "/home", 
    children: [
        { path: 'Home', name: '主页', component: () => import('../views/Home.vue')},
        { path: 'MediaType', name: '文件类型管理', component: () => import('../views/MediaType.vue')},
        { path: 'File', name: '文件管理', component: () => import('../views/File.vue')},
        { path: 'Banner', name: 'banner管理', component: () => import('../views/Banner.vue')},
      ]
    }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// 重置路由
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: 'history',
    routes
  })
}

router.beforeEach((to, from, next) => {
  console.log("to.name:",to.name)
  localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称
  store.commit("setPath")
  if (!to.matched.length) {
    next("/404")
  } else {
    if(!sessionStorage.getItem("token")){
      if(to.path != '/login'){
        next('/login')
      }else{
        next()
      }
    }else{
      next()
    }
  }
})

export default router
